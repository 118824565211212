@import './abstracts/variables';

.accounts {
	& h1 {
		display: block;
		padding: 2rem;
		margin: auto;
		background-color: $ternary-color;
		color: white;
		font-size: 3rem;
		font-weight: 400;
		text-align: center;
	}

	&__button {
		& svg {
			font-size: 3rem;
			margin-right: 1rem;
		}
	}

	table.minimalistBlack {
		margin: 4rem 0;
		border: 3px solid #000000;
		width: 100%;
		text-align: left;
		border-collapse: collapse;
	}
	table.minimalistBlack td,
	table.minimalistBlack th {
		border: 1px solid #000000;
		padding: 5px 4px;
	}
	table.minimalistBlack tbody td {
		font-size: 1.6rem;
	}
	table.minimalistBlack thead {
		background: #cfcfcf;
		background: -moz-linear-gradient(
			top,
			#dbdbdb 0%,
			#d3d3d3 66%,
			#cfcfcf 100%
		);
		background: -webkit-linear-gradient(
			top,
			#dbdbdb 0%,
			#d3d3d3 66%,
			#cfcfcf 100%
		);
		background: linear-gradient(
			to bottom,
			#dbdbdb 0%,
			#d3d3d3 66%,
			#cfcfcf 100%
		);
		border-bottom: 3px solid #000000;
	}
	table.minimalistBlack thead th {
		font-size: 2rem;
		font-weight: bold;
		color: #000000;
		text-align: left;
	}
	table.minimalistBlack tfoot {
		font-size: 14px;
		font-weight: bold;
		color: #000000;
		border-top: 3px solid #000000;
	}
	table.minimalistBlack tfoot td {
		font-size: 14px;
	}

	button {
		display: flex;
		align-items: center;
		justify-content: center;
		background-color: saturate($ternary-color, 20%);
		justify-self: center;
		color: white;
		border-radius: 0;
		border: none;
		font-size: 2rem;
		font-family: Montserrat;
		padding: 1rem 2rem;
		cursor: pointer;
		margin: 2rem auto 0;
		width: 40%;
		box-shadow: 5px 11px 25px -5px rgba(12, 12, 12, 0.39);
		transform: translateY(-2px);
		transition: all 0.1s ease-in-out;
		outline: 5px solid saturate($ternary-color, 100%);

		&:hover {
			background-color: saturate($ternary-color, 40%);
			outline: 7px solid saturate($ternary-color, 100%);
			width: 42%;
		}

		&:active {
			background-color: saturate($ternary-color, 80%);
			transform: translateY(0px);
		}

		&:hover svg:first-child {
			transform: translateX(0px);
		}

		&:active svg:last-child {
			transform: translateX(25px);
		}
	}
}
