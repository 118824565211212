@import "./abstracts/variables";
@import "./abstracts/base";
@import "./abstracts/mixins";
@import "./abstracts/functions";
@font-face {
         font-family: 'DINPro-Regular';
         font-style: normal;
         font-weight: normal;
         src: local('../font/dinpro-regular/DINPro-Regular_13937.woff'), url('../font/dinpro-regular/DINPro-Regular_13937.woff') format('woff');
}
.spinner{
   background-color: $secondary-color;
   text-align: center;
   display: flex;
   flex-direction: column;
   justify-content: center;
   align-items: center;
   width: 100%;
   height: 100vh;
   
   & > img{
      width: 30rem;
      height: auto;
      margin-bottom: 3rem;
   }
} 

