@import './abstracts/variables';
@import './abstracts/mixins';
.footer{
   height: auto;
   padding: 2rem;
   background-color: $secondary-color;
   color: $text-color-white;
   font-weight: 400;

   &__contact svg{
      font-size: 2.5rem;
   }

   &__contact a{
      color:inherit;
      text-decoration: none;
   }

   &__contact > ul{
      list-style: none;
   }

   &__contact > h2{
      font-size: 2rem;
      text-align: center;
      margin-bottom: 0.5rem;
      
   }
   
   &__contact ul > li{
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      margin-top: 2rem;
      font-size: 1.5rem;
      
   }

   &__contact ul > li > h3{
      margin-left: 1rem;
      font-weight: 400;
   }

   &__logo{
      margin: 2rem 0 1rem;
      width: 100%;
      height: auto;
   }
   &__logo > img{
      height: 100%;
      display: block;
      margin: auto auto;
   }
   
   &__misc > img{
      height: 5rem;
      margin: 2rem 1.5rem;
      flex:1 0 100%;
   }

   &__rights > p{
      text-align: center;
   }
   
   
}
.footer .navbar__socialMedia span {
      @include respond (sm-size){
         display: block;
      }
   }

   .footer::before{
     content: "WhatsApp Sipariş Hattı";
     position: relative;
     color: black;
     top:-10rem;
     left: 8rem;
     font-weight: 700;
     font-size: 2rem;
   }