.section-meals {
    padding: 0;
    margin: 8rem 0 1rem;
    overflow: hidden;
}

.meals-showcase {
    list-style: none;
    width: 100%  ;
}

.meals-showcase li {
    display: block;
    float: left;
    width: 25%;
}

.meal-photo {
    width: 100%;
    margin: 0;
    overflow: hidden;
    background-color: #000;
}

.meal-photo img {
   display: block;
   margin: auto;
    opacity: 0.7;
    width: 100%;
    height: 20rem;
    -webkit-transform: scale(1.15);
    -ms-transform: scale(1.15);
    transform: scale(1.15);
    -webkit-transition: -webkit-transform 0.5s, opacity 0.5s;
    transition: transform 0.5s, opacity 0.5s;
    object-fit: cover;
}

.meal-photo img:hover {
    opacity: 1;
    -webkit-transform: scale(1.03);
    -ms-transform: scale(1.03);
    transform: scale(1.03);
}