@import "./mixins";
@import "./variables";
*,
*::after,
*::before {
    margin: 0;
    padding: 0;
    box-sizing: inherit;
}
a{
      text-decoration: none;
      color: inherit;
   }

html {
    // This defines what 1rem is
    font-size: 62.5%; //1 rem = 10px; 10px/16px = 62.5%

    @include respond(lg-size) { // width < 1200?
        font-size: 56.25%; //1 rem = 9px, 9/16 = 50%
    }

    @include respond(md-size) { // width < 900?
        font-size: 50%; //1 rem = 8px, 8/16 = 50%
    }
    
    @include respond(xl-size) {
        font-size: 75%; //1rem = 12, 12/16
    }
}

body {
    box-sizing: border-box;

    @include respond(md-size) {
        padding: 0;
    }
}

::selection {
    background-color: $primary-color;
    color: $color-white;
}