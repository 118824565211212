@import "./abstracts/mixins";
.header { 
   margin: 0; 
   position: relative; 
   object-fit: cover;
   width: 100%;
   height: auto;
   box-shadow: 0 0 20px rgba(0,0,0,0.4); 
   background:rgba(76,36,28,1);
   overflow: hidden;
}
