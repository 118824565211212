@import './abstracts/variables';
@import './abstracts/mixins';

section {
	padding: 9rem 2rem 5rem;
}

.product {
	min-height: 90vh;

	& button {
		background-color: saturate($ternary-color, 20%);
		color: white;
		border-radius: '0';
		border: none;
		font-size: 2rem;
		font-family: Montserrat;
		padding: 2rem 2rem;
		cursor: pointer;
		margin-top: 2rem;
		width: 100%;
		box-shadow: 0 0 20px;
		transform: translateY(-3px);
		transition: all 0.1s ease-in-out;

		&:hover {
			background-color: saturate($ternary-color, 40%);
			transform: translateY(-2px);
			width: 100%;
		}
		&:active {
			background-color: saturate($ternary-color, 80%);
			transform: translateY(0px);
			width: 100%;
		}
	}

	&__info {
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@include respond(md-size) {
			margin-top: 4rem;
		}

		&__header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			height: auto;
			padding: 1rem;
			font-size: 2rem;

			& > h1 {
				font-weight: 400;
				margin-right: 4rem;
			}

			:last-child {
				align-self: flex-end;
			}
		}

		&__description {
			display: flex;
			flex-direction: column;
			justify-content: space-between;

			& > h3 {
				display: flex;
				align-items: center;
				padding: 1.5rem;
				height: auto;
				margin: 1rem 0;
				background-color: $ternary-color;
				color: white;
				font-size: 2rem;
				font-weight: 400;
				text-align: center;
			}
		}

		&__form {
			margin-top: 4rem;
			display: flex;
			justify-content: center;
			font-size: large;
			flex-wrap: wrap;
			justify-content: space-around;
			align-items: center;
			@include respond(md-size) {
			justify-content: center;

		}
		}
	}

	&__img {
		object-fit: contain;
	}

	&__img > img {
		position: relative;
		display: block;
		margin: auto auto;
		width: 80%;
		height: auto;
		box-shadow: 5px 11px 25px -5px rgba(12, 12, 12, 0.349);
	}

	&__other {
		margin-top: 3rem;

		& h2 {
			display: block;
			width: 100%;
			background-color: $ternary-color;
			color: $color-white;
			font-size: 4rem;
			padding: 2rem;
			margin: 1rem auto;
			text-align: center;
			font-weight: 400;
		}

		@include respond(md-size) {
			font-size: 4rem;
		}
	}

	.carousel-item {
		padding: 0 2.5rem;
	}

	.item {
		padding: 0 0.5rem;

		& p {
			font-size: inherit;
			@include respond(sm-size) {
				font-size: 1.5rem;
			}
		}
	}
}

.product__info__description ul {
	margin-top: 3rem 0 0 1rem;
	list-style: circle;
	flex-direction: column;
	padding: 0 3rem;
	& li {
		margin-top: 1rem;
		font-size: 1.5rem;
		line-height: 130%;
		& span {
			color: red;
		}
	}
}
