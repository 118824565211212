@import "./abstracts/mixins";

.navbar__logo > img {
   position: relative;
   top: 42px;
   height: 160px;
   width: auto;
   text-shadow: 0 0 20px #fff;

   @include respond (xl-size){
         top: 52px;
         height: 180px
      }

   @include respond (lg-size){
         top: 42px;
         height: 150px;
      }

   @include respond (md-size){
         top: 35px;
         height: 125px;
      }
      
}

.footer__logo > img{
   // margin: auto auto;
   // display: block;
   position: relative;
   height: 160px;
   width: 90%;
   text-shadow: 0 0 20px #fff;
    @include respond (xl-size){
         height: 180px
      }

   @include respond (lg-size){
         height: 150px;
      }

   @include respond (md-size){
         height: 125px;
      }
      
   @include respond (sm-size){
      height: 95px;
   }
}