@import './abstracts/mixins.scss';
.about{
   padding: 8rem 5rem;

   @include respond (md-size){
         padding: 5rem 2rem;
      }
    @include respond (sm-size){
         padding: 3rem 1rem;
      }

   &__img{
   position: relative;
   width: 100%;
   object-fit: cover;
   }

   &__img > img{
      display: block;
      width: 100%;
      margin: auto auto;
      vertical-align: middle;
      
      @include respond (lg-size){
         margin: 0 auto;
         width: 75%;
      }
   }
   
   &__img > button{
      font-size: 4rem;
      position: absolute;
      z-index: 100;
   }

   &__us{
   font-family: 'Lora';
   text-align: center;
   }
   
   &__us > h1{
      margin-top: 0;
      font-size: 5rem;
      @include respond (lg-size){
         margin-top: 2rem;
      }
   }

   &__us > p {
      margin-top: 2rem;
      font-size: 1.7rem;
      line-height: 175%;
      text-indent: 1rem;
   }
}