@import './abstracts/variables';
@import'./abstracts/mixins';

.card {
   margin: 0.4rem auto;
   display: block;
   position: relative;
   display: flex;
   flex-direction: column;
   justify-content: flex-start;
   width: 100%;
   height: auto;

   &__media{
      background-color: transparent;
      text-align: center;
      border: 0px solid $primary-color;
      box-shadow:5px 11px 25px -5px rgba(12, 12, 12, 0.349); 
      transition: all .2s ease-out;
      // flex: 1 0 100%;
      
      &:hover{
      border: 5px solid $primary-color;

      @include respond (sm-size){
         border: 0px solid transparent;
      }
      }
      @media (hover:none) {
         &:hover{
         border: 0px solid transparent;
         }
      }
   }
   
   &__media > img {
      display: block;
      margin: auto auto;
      width: 100%;
      object-fit: cover;
   }

   &__title{
      margin-top:1rem;
      padding: 0.5rem 0;
      font-size: 2rem;
      text-transform: capitalize;
      background-color: rgba(138, 138, 138, 0.055);
   }

   &__content{
      margin-top: 0.6rem;
      padding: 0.5rem 0;
      font-size: 1.9rem;
      font-weight: 700;
      background-color: rgba(138, 138, 138, 0.055);
   }

   &__content > span{
      font-size: 1.5rem;
      margin-left: 5px;
   }
}