.whatsapp{
   position: sticky;
   height: auto;
   width: 4rem;
   bottom: 0rem;

}

.whatsapp img{
   margin: 3rem 3rem;
   height: 6rem;
   width: auto;
   animation: shake 10s cubic-bezier(.36,.07,.19,.97) both;
   animation-iteration-count: infinite;
   transform: translate3d(0, 0, 0);
   backface-visibility: hidden;
   perspective: 1000px;
   z-index: 1000;
   
}

@keyframes shake {
  1%, 10% {
    transform: translate3d(-1px, 0, 0);
  }
  
  2%, 9% {
    transform: translate3d(3px, 0, 0);
  }

  7.5%, 5%, 7% {
    transform: translate3d(-5px, 0, 0);
  }

  4%, 6% {
    transform: translate3d(5px, 0, 0);
  }
  100% {
    transform: translate3d(0px, 0, 0);
  }
}