/* Palette generated by Material Palette - materialpalette.com/amber/light-green */

$primary-color:         #FFC107;
$dark-primary-color:    #FFA000; 
$light-primary-color:   #FFECB3; 
$secondary-color:       #4C241C;
$ternary-color:         #2C3D2E;
$ternary-color-light:   #48623E;
$accent-color-succes:   #66cc08; 
$accent-color-warning:  #FE4F34;
$primary-text-color:    #212121; 
$secondary-text-color:  #757575;
$divider-color :        #BDBDBD;

$color-white: #fff;
$text-color-white: rgb(241, 241, 241)